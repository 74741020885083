@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.saas-landing {
  display: grid;
  grid-template-rows: auto 1fr auto; 
  min-height: 100vh;
  text-align: center;
  background-color: #2c3e50; 
  color: #ecf0f1;
}

header {
  display: grid;
  grid-template-columns: 1fr; 
  grid-template-rows: auto auto; 
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.auth-buttons {
  grid-row: 1; 
  grid-column: 1; 
  justify-self: end; 
}

.brand {
  grid-row: 2; 
  grid-column: 1; 
}

.brand h1 {
  margin: 0;
  font-size: 2em;
}

.brand p {
  font-size: 18px;
}

.login,
.signup {
  margin-left: 10px;
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login {
  background-color: #3498db; 
  color: #ecf0f1;
}

.signup {
  background-color: #e74c3c; 
  color: #ecf0f1;
}

.features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin: 40px 0;

  margin-left: 20px;
  margin-right: 20px;
}

.feature {
  padding: 20px;
  border-radius: 8px;
  background-color: #34495e;
  border: 1px solid #2c3e50;
  transition: transform 0.3s ease-in-out;
  position: relative; 
}

.feature.most-popular::before {
  content: 'Most Popular';
  position: absolute;
  top: -15px; 
  left: 50%;
  transform: translateX(-50%);
  background-color: #e74c3c; 
  color: #ecf0f1;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
}

.feature:hover {
  transform: scale(1.05);
}

.feature h2 {
  margin-bottom: 10px;
  color: #ecf0f1;
}

.feature p {
  color: #bdc3c7;
}

.cta {
  background-color: #385e77;
  padding: 60px 0;
  border-radius: 8px;
}

.cta h2 {
  margin-bottom: 20px;
  color: #ecf0f1;
}

.cta-button {
  padding: 12px 24px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  background-color: #e74c3c;
  color: #ecf0f1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
}

.cta-button:hover {
  filter: brightness(0.9);
}

footer {
  background-color: #2c3e50;
  color: #bdc3c7;
  padding: 10px;
}

.login-buttons1 {
  display: none;
}

.login1 {
  margin-left: 10px;
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #3498db; 
  color: #ecf0f1;
}

.signup1 {
  margin-left: 10px;
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #e74c3c; 
  color: #ecf0f1;
}

@media screen and (max-width: 800px) {
  .auth-buttons {
    display: none;
  }

  .login-buttons1 {
    margin-top: 10vh;
    display: block;
  }

  .login1 {
    margin-left: 10px;
    padding: 25px 50px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #3498db; 
    color: #ecf0f1;
  }

  .signup1 {
    margin-left: 10px;
    padding: 25px 50px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #e74c3c; 
    color: #ecf0f1;
  }
}
/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar handle */
  border-radius: 5px; /* Rounded corners of the scrollbar handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar handle on hover */
}
@keyframes popIn {
  0% {
    transform: translateX(120%);
    opacity: 0;
  }
  60% {
    transform: translateX(8%);
    opacity: 1;
  }
  80% {
    transform: translateX(4%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes popOut {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(4%);
  }
  40% {
    transform: translateX(8%);
  }
  100% {
    transform: translateX(120%);
    opacity: 0;
  }
}

.alert-pop-in {
  animation: popIn 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

.alert-pop-out {
  animation: popOut 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53) forwards;
}

.firebase-emulator-warning {
  display: none;
}