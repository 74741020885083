/* AuthPage.css */

body {
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .auth-page {
    display: grid;
    grid-template-rows: auto 1fr auto;
    min-height: 100vh;
    text-align: center;
    padding: 40px;
    background-color: #2c3e50;
    color: #ecf0f1;
  }
  
  header {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }
  
  .brand h1 {
    margin: 0;
    font-size: 2em;
  }
  
  .brand p {
    font-size: 18px;
  }
  
  .auth-form {
    width: 300px;
    margin: 0 auto;
    text-align: left;
  }
  
  .auth-form h2 {
    margin-bottom: 20px;
    color: #ecf0f1;
  }
  
  form {
    display: grid;
    grid-gap: 15px;
  }
  
  label {
    color: #ecf0f1;
    font-size: 14px;
  }
  
  input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    margin-top: 5px;
  }
  
  
  p {
    color: #bdc3c7;
    font-size: 14px;
    margin-top: 15px;
  }
  
  a {
    color: #3498db;
    text-decoration: none;
  }
  
  footer {
    background-color: #2c3e50;
    color: #bdc3c7;
}
.auth-toggle {
    display: flex;
    justify-content: space-between; /* Spread out the buttons */
    margin-bottom: 20px;
  }
  
  .auth-toggle button {
    flex: 1; /* Equal width for both buttons */

    color: #ecf0f1;
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
    margin-left: 10px;
    transition: background-color 0.3s ease-in-out;
  }
  
  
  .auth-form {
    width: 300px;
    margin: 0 auto;
    text-align: left;
  }
  
  .google-sign-in {

    margin-top: 20px;
    text-align: center;
  }
  
  .google-sign-in-btn {
    width: 250px;
    background-color: #dd4b39; /* Google Red */
    color: #ffffff;
    padding: 10px 20px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .google-sign-in-btn:hover {
    background-color: #c53727; /* Darker shade of Google Red on hover */
  }

  hr {
    margin: 20px 0;
    border: none;
    border-top: 1px solid #838383;
  }